import * as React from 'react';
import {ReactNode} from 'react';
import styled from 'styled-components';
import {Layout} from "./Layout";
import {PageSection} from "../components/PageSection";
import {PageState} from "./PageState";

export type MinimalPageProps = {
    children: ReactNode;
    title: string;
    img: string;
} & PageState;
export function MinimalPage({title, children, img, ...pageStateProps}: MinimalPageProps) {
    return <Container {...pageStateProps}>
        <Content id={"content"} title={title} variant={"left"} img={img}>
            {children}
        </Content>
    </Container>
}

const Container = styled(Layout)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  border: 1px solid;  
`;

const Content = styled(PageSection)`
  margin-top: 0;
  padding-top: 0;
  &:after {
    display: none;
  }
`;

